import React from "react"
import { Link, graphql } from "gatsby"
import HomeScreen from "../components/HomeScreen"

import SEO from "../components/seo"
import Gallery from "../components/Gallery"
import Entry from "../../types/entry"
import Html from "../../types/html"
import Story from "../../types/story"
import LayoutContextConsumer from "../layouts/LayoutContext"
import Picture from "../../types/picture"
import Portrait from "../../types/potrait"

interface Props {
  data: {
    entries: {
      nodes: Entry[]
    }
    stories: {
      nodes: {
        html: Html
        parent: {
          name: string
        }
      }[]
    }
    site: {
      siteMetadata: {
        title: string
      }
    }
    pictures: {
      nodes: (Picture & Portrait)[]
    }
  }
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const entries = data.entries.nodes
  return (
    <LayoutContextConsumer>
      {({ layoutState, toggleNav }) => (
        <React.Fragment>
          <HomeScreen></HomeScreen>
          {<Gallery urlPrefix="/entry" entries={entries}></Gallery>}
        </React.Fragment>
      )}
    </LayoutContextConsumer>
  )
}

export default IndexPage

export const query = graphql`
  query {
    entries: allDatoCmsEntry(sort: { fields: createdDate, order: DESC }, filter: {specproekt: {eq: false}}) {
      nodes {
        author
        city
        cityCoords {
          longitude
          latitude
        }
        country
        createdDate(formatString: "dd.MM.YYYY")
        firstName
        lastName
        patriName
        picture {
          url
          fixed300: fixed(height: 300, width: 300) {
            ...GatsbyDatoCmsFixed
          }
          fixed450: fixed(height: 450, width: 450) {
            ...GatsbyDatoCmsFixed
          }
          fixed600: fixed(height: 600, width: 600) {
            ...GatsbyDatoCmsFixed
          }
          fixed900: fixed(height: 900, width: 900) {
            ...GatsbyDatoCmsFixed
          }
          fixed1200: fixed(height: 1200, width: 1200) {
            ...GatsbyDatoCmsFixed
          }
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        storyNode {
          childMarkdownRemark {
            excerpt
            html
          }
        }
        portrait {
          url
          fixed50: fixed(height: 50, width: 50) {
            ...GatsbyDatoCmsFixed
          }
          fixed100: fixed(height: 100, width: 100) {
            ...GatsbyDatoCmsFixed
          }
          fixed150: fixed(height: 150, width: 150) {
            ...GatsbyDatoCmsFixed
          }
          fixed250: fixed(height: 250, width: 250) {
            ...GatsbyDatoCmsFixed
          }
          fixed600: fixed(height: 600, width: 600) {
            ...GatsbyDatoCmsFixed
          }
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        storyTitle
        url
        id
      }
    }
  }
`
