import React from "react"
import { Link } from "gatsby"

interface Props {}

export const HomeScreen: React.FC<Props> = () => {
  const text: string[] = [
    `Их придумали, нарисовали и прислали нам люди из разных городов и стран.`,
    `Главный герой всех картин и историй – Красный мишка©, персонаж музея-мастерской «Друг мой мишка» (г. Красногорск) Наташи Усачевой.`,
  ]

  const pClassName = "font-body my-4 text-center text-xl max-w-2xl"

  return (
    <div className="flex-col flex items-center justify-center">
      <h1 className="text-center px-4 font-display text-6xl my-8">
        Маленькие истории Красного мишки
      </h1>
      <picture className="h-64 w-auto mb-6">
        <img
          className="block h-64"
          src="/images/logo-full.svg"
          alt="Истории Красного Мишки"
        />
      </picture>
      <div className="px-4 flex flex-col w-100 mb-8">
        {text.map((t, i) => (
          <p
            key={i}
            className={pClassName}
            dangerouslySetInnerHTML={{ __html: t }}
          ></p>
        ))}
        <p className={pClassName}>
          Друг,{" "}
          <Link className="text-blue-600 underline" to="/participate">
            присоединяйся
          </Link>
          ! Расскажи свою историю Красного мишки!
        </p>
      </div>
    </div>
  )
}

export default HomeScreen
